<template>
  <b-modal visible
           v-bind="$attrs"
           size="lg"
           @ok="confirmTransfer"
           @cancel="cancelTransfer"
           @hide="modalHided"
           :ok-title="'Apply changes'"
           :ok-disabled="$v.$invalid">
    <b-container class="bv-example-row">
      <b-row>
        <b-col>
          <b-form-group label="Original PT">
            <b-form-select v-model="selectedOriginPt"
                           :options="originPtOptions"
                           :disabled="editingExistingTransfer"
                           @click.native="$v.selectedOriginPt.$touch()"
                           :state="shouldValidateInput($v.selectedOriginPt)">
            </b-form-select>
            <b-form-invalid-feedback :state="shouldValidateInput($v.selectedOriginPt)">
              {{ getErrorMessage('selectedOriginPt') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Transfer Type">
            <b-form-select v-model="selectedTransferType"
                           :options="transferTypes" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label="Transfer Date / Start Date">
            <b-form-input placeholder="MM-DD-YYYY"
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          v-model="selectedStartDate"
                          ref="start-date-field"
                          :disabled="selectedStartDateDisable"
                          type="date"
                          @click.native="$v.selectedStartDate.$touch()"
                          :state="shouldValidateInput($v.selectedStartDate)" />
            <small class="text-danger" v-if="isTodayDate(selectedStartDate)">
              Your transfer will be completed within the next hour after you click on 'Apply changes'
            </small>
            <b-form-invalid-feedback :state="shouldValidateInput($v.selectedStartDate)">
              {{ getErrorMessage('selectedStartDate') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label="Transfer End Date">
            <b-form-input placeholder="MM-DD-YYYY"
                          pattern="[0-9]{2}-[0-9]{2}-[0-9]{4}"
                          v-model="selectedEndDate"
                          :disabled="isDefinitiveTransfer"
                          type="date"
                          @click.native="$v.selectedEndDate.$touch()"
                          :state="shouldValidateInput($v.selectedEndDate)" />
            <b-form-invalid-feedback :state="shouldValidateInput($v.selectedEndDate)">
              {{ getErrorMessage('selectedEndDate') }}
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';

const CONTEXT_DATE_FORMAT = 'YYYY-MM-DD';

export default {
  name: 'ScheduleBulkTransferModal',
  props: {
    transferId: {
      type: Number,
      required: false,
    },
    transferType: {
      type: String,
      required: false,
    },
    therapistsList: {
      type: Array,
      required: false,
    },
    originPtId: {
      type: Number,
      required: false,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    datesFormat: {
      type: String,
      default: 'YYYY-MM-DD',
    },
  },
  data() {
    return {
      editingExistingTransfer: !!this.transferId,
      selectedStartDateDisable: false,
      selectedOriginPt: null,
      selectedStartDate: null,
      selectedEndDate: null,
      auxSelectedEndDate: null,
      selectedTransferType: this.transferType || 'temporary',
      transferTypes: [
        { value: 'temporary', text: 'Temporary' },
        { value: 'permanent', text: 'Permanent' },
      ],
    };
  },
  mounted() {
    if (this.editingExistingTransfer) {
      const parsedStartDate = this.startDate && moment(this.startDate, this.datesFormat);
      const parsedEndDate = this.endDate && moment(this.endDate, this.datesFormat);

      this.selectedStartDate = parsedStartDate?.format(CONTEXT_DATE_FORMAT);
      this.selectedEndDate = parsedEndDate?.format(CONTEXT_DATE_FORMAT);
      this.auxSelectedEndDate = this.selectedEndDate;

      this.selectedStartDateDisable = this.isBeforeDate(parsedStartDate);

      this.selectedOriginPt = this.originPtId;
    }
  },
  validations() {
    return this.rules;
  },
  computed: {
    isDefinitiveTransfer() {
      return this.selectedTransferType === 'permanent';
    },
    rules() {
      const baseRules = {
        selectedOriginPt: { required },
        selectedTransferType: { required },
        selectedStartDate: {
          required,
          beforeEndDate: date => !this.selectedEndDate || this.isBeforeDate(date, this.selectedEndDate),
        },
      };

      if (!this.isDefinitiveTransfer) {
        Object.assign(
          baseRules,
          {
            selectedEndDate: {
              required: date => this.isDefinitiveTransfer || required(date),
              isFuture: date => this.isDefinitiveTransfer || this.isFutureDate(date),
              afterStartDate: date => this.isDefinitiveTransfer || !this.selectedStartDate || this.isFutureDate(date, this.selectedStartDate),
            },
          },
        );
      }

      if (!this.selectedStartDateDisable) {
        Object.assign(baseRules.selectedStartDate, { isTodayOrFuture: date => this.isTodayOrFutureDate(date) });
      }

      return baseRules;
    },
    originPtOptions() {
      return this.therapistsList.map(t => ({
        value: t.id,
        text: `${t.firstname} ${t.lastname} - ${t.id} ${t.deleted_at ? ' (deleted)' : ''}`,
      }))
        .sort((a, b) => {
          if (a.text === b.text) {
            return 0;
          }
          return a.text > b.text ? 1 : -1;
        });
    },
  },
  methods: {
    shouldValidateInput(inputValidation) {
      return inputValidation && inputValidation.$dirty && inputValidation.$invalid ? false : null;
    },
    getErrorMessage(modelKey) {
      if (!this.rules[modelKey]) {
        return null;
      }
      const rules = Object.keys(this.rules[modelKey]);
      const failedRule = rules.find(rule => this.$v[modelKey][rule] === false);
      const errorMessagesMapping = {
        required: 'Field is required',
        beforeEndDate: 'Starting date should be before End date',
        isFuture: 'The transfer date should be from next calendar day onwards',
        isTodayOrFuture: 'The transfer date should be from today onwards',
        afterStartDate: 'The transfer end date should be from the next calendar day onwards and after the start date',
      };
      return errorMessagesMapping[failedRule] || 'Invalid field';
    },
    isBeforeDate(date, referenceDate) {
      const referenceDateToUse = !referenceDate ? moment() : moment(referenceDate);
      return moment(date).isBefore(referenceDateToUse);
    },
    isFutureDate(date, referenceDate) {
      const referenceDateToUse = !referenceDate ? moment() : moment(referenceDate);
      return moment(date).isAfter(referenceDateToUse);
    },
    isTodayOrFutureDate(date, referenceDate) {
      const referenceDateToUse = !referenceDate ? moment().format(this.datesFormat) : moment(referenceDate);
      return moment(date).isSameOrAfter(referenceDateToUse);
    },
    isTodayDate(date) {
      return moment(date).isSame(moment().format(this.datesFormat));
    },
    modalHided(event) {
      if ([ 'esc', 'backdrop' ].includes(event.trigger)) this.cancelTransfer();
    },
    cancelTransfer() {
      this.$emit('close');
    },
    confirmTransfer() {
      this.$emit('save', {
        id: this.transferId,
        originPt: this.selectedOriginPt,
        type: this.selectedTransferType,
        startDate: this.selectedStartDate,
        endDate: this.selectedEndDate,
      });
    },
  },
  watch: {
    selectedTransferType(newType) {
      if (newType === 'permanent') {
        this.auxSelectedEndDate = this.selectedEndDate;
        this.selectedEndDate = undefined;
      } else {
        this.selectedEndDate = this.auxSelectedEndDate;
        if (this.selectedEndDate) {
          this.$v.selectedEndDate.$touch();
        }
      }
    },
  },
};
</script>
