<template>
  <section id="bulk-transfer-list-module">

    <header class="d-flex mt-4 mb-4 justify-content-between">
      <h4>Member Bulk Transfers</h4>
      <b-button data-testId="schedule-new-bulk-transfer" variant="primary" @click="openTransferModal">
        New bulk transfer
        <feather type="plus"></feather>
      </b-button>
    </header>

    <!-- Scheduled bulk transfer -->
    <table data-testId="scheduled-transfers-table" class="table bulk-transfers-list-table mx-0 mb-2">
      <thead class="thead-light">
      <tr class="bulk-transfers-list-table__headings">
        <th scope="col" class="ids-col">ID</th>
        <th scope="col">Original PT (Name - ID)</th>
        <th scope="col">Transfer Type</th>
        <th scope="col">Transfer Date/ Start Date</th>
        <th v-b-tooltip.hover
            scope="col"
            title="Date when members are transferred back to original PT. Only for temporary transfers.">
          Transfer End Date
        </th>
        <th scope="col">Status</th>
        <th scope="col">Actions</th>
      </tr>
      </thead>

      <tbody v-if="loadingPage">
      <tr>
        <td colspan="7" class="text-center">
          <b-spinner small class="mr-1"></b-spinner>
          <strong>Loading...</strong></td>
      </tr>
      </tbody>

      <tbody v-else-if="!scheduledTransfers.length">
      <tr>
        <td colspan="7" class="text-center"> No member bulk transfers added, add one using the button above </td>
      </tr>
      </tbody>
      <tbody v-else>
      <tr class="bulk-transfers-list-table__item"
          v-for="(transfer, index) in scheduledTransfers"
          :key="index">
        <td class="align-middle"> {{ transfer.id }}</td>
        <td class="align-middle">
          {{ transfer.original_pt.firstname }} {{ transfer.original_pt.lastname }} - {{ transfer.from_therapist_user_id }}
        </td>
        <td class="align-middle"> {{ transfer.transfer_type | capitalize }}</td>
        <td class="align-middle"> {{ transfer.start_date | formattedDate }}</td>
        <td class="align-middle">
          <template v-if="transfer.transfer_type === 'permanent'"> -</template>
          <template v-else> {{ transfer.end_date | formattedDate }}</template>
        </td>
        <td class="align-middle">
          <template v-if="transfer.status === '1st_transfer_completed'">
            <b-tooltip
                target="status-handover-done-label"
                triggers="hover"
                placement="left"
                custom-class="dark-tooltip">
              Members were temporarily transferred to the coverage Specialist.
            </b-tooltip>
            <span id="status-handover-done-label"> Handover done </span>
           </template>
          <template v-else> {{ transfer.status | capitalize }} </template>
        </td>
        <td class="align-middle bulk-transfers-list-table__item-actions">
          <b-button v-b-tooltip.hover
                    title="Edit"
                    size="sm"
                    variant="light"
                    class="bulk-transfers-list-table__item-actions-button"
                    v-if="transfer.canBeEdited"
                    :disabled="transferBeingDeleting"
                    @click="editTransfer(transfer)">
            <feather type="edit-2"></feather>
          </b-button>
          <b-button v-b-tooltip.hover
                    size="sm"
                    title="Delete"
                    variant="light"
                    class="bulk-transfers-list-table__item-actions-button"
                    v-if="transfer.canDeleted"
                    :disabled="transferBeingDeleting"
                    @click="handleDelete(transfer)">
              <feather type="trash"></feather>
          </b-button>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Pagination -->
    <div class="row pt-2">
      <div class="col-12 d-flex justify-content-center">
        <b-pagination :disabled="loadingPage"
                      @change="changePage"
                      v-model="pagination.currentPage"
                      :total-rows="pagination.totalRows"
                      :per-page="pagination.itemsPerPage"
                      size="md">
        </b-pagination>
      </div>
    </div>

    <b-modal id="schedule-new-transfer"
             title="New bulk transfer"
             size="lg">
    </b-modal>

    <schedule-bulk-transfer-modal v-if="showEditScheduledTransfer"
                                  data-testId="schedule-bulk-transfer-modal"
                                  :transfer-id="transferToEdit.id"
                                  :transfer-type="transferToEdit.transfer_type"
                                  :therapists-list="therapistsList"
                                  :origin-pt-id="editingTransfer ? transferToEdit.from_therapist_user_id : undefined"
                                  :start-date="transferToEdit.start_date"
                                  :end-date="transferToEdit.end_date"
                                  :title="!editingTransfer ? 'New bulk transfer' : 'Edit bulk transfer'"
                                  @save="confirmTransferChanges"
                                  @close="closeTransferModal">
    </schedule-bulk-transfer-modal>
  </section>

</template>

<script>

// Components
import ScheduleBulkTransferModal from '@/components/ClinicalOps/ScheduleBulkTransferModal.vue';

import pagination from '@/mixins/lists/pagination';

import axios from 'axios';
import moment from 'moment';
import { capitalize } from '@/core/filters';

const TRANSFER_TYPES = {
  TEMPORARY: 'temporary',
  PERMANENT: 'permanent',
};

const TRANSFER_STATUS = {
  SCHEDULED: 'scheduled',
  COMPLETED: 'completed',
};

export default {
  name: 'MembersBulkTransfer',
  components: { ScheduleBulkTransferModal },
  mixins: [ pagination ],
  filters: {
    capitalize: value => capitalize(value),
    formattedDate: date => moment(date).format('DD/MM/YYYY'),
  },
  data() {
    return {
      loadingPage: true,
      showEditScheduledTransfer: false,
      newTransfer: {},
      transferToEdit: {},
      transferBeingDeleting: null,
      scheduledTransfers: [],
      therapistsList: [],
      currentDate: moment().subtract(0, 'days').startOf('day'),
    };
  },
  async mounted() {
    await this.onMountedHook();
  },
  computed: {
    editingTransfer() {
      return !!Object.keys(this.transferToEdit).length;
    },
  },
  methods: {
    onPageChange() {
      this.fetchScheduledTransfers();
    },
    async onMountedHook() {
      this.loadingPage = true;
      try {
        await this.fetchAllAvailableTherapistList();
        await this.fetchScheduledTransfers();
      } catch (e) {
        console.error('Error booting bulk transfers list', e);
      } finally {
        this.loadingPage = false;
      }
    },
    async reFetchScheduledTransfers() {
      this.loadingPage = true;
      await this.fetchScheduledTransfers();
      this.loadingPage = false;
    },
    async fetchScheduledTransfers() {
      const params = {
        limit: this.pagination.itemsPerPage,
        offset: (this.pagination.currentPage - 1) * this.pagination.itemsPerPage,
      };

      try {
        const { data } = await axios.get('v1/member-bulk-transfer', { params });
        const { entries: transfersItems, total: totalTransfers } = data;
        this.scheduledTransfers = this.parseTransferPageItems(transfersItems);
        this.pagination.totalRows = totalTransfers;
        return Promise.resolve(this.scheduledTransfers);
      } catch (e) {
        console.error('Error fething bulk transfers list page', e);
        this.$noty.error('Failed to fetch users: ', e);
        return Promise.reject(e);
      }
    },
    async fetchAllAvailableTherapistList() {
      const users = await this.fetchAvailableTherapistList();
      if (users.total > this.therapistsList.length) {
        return this.fetchAllAvailableTherapistList();
      }
      return Promise.resolve(this.therapistsList);
    },
    async fetchAvailableTherapistList() {
      const queryParams = new URLSearchParams({
        limit: 50,
        offset: this.therapistsList.length,
        role: 'Professional',
        client: 'back-office',
      });

      try {
        const { data } = await axios.get('v1/users', { params: queryParams });
        this.therapistsList = this.therapistsList.concat(data.users.sort());
        return data;
      } catch (e) {
        console.error('Error fetching available therapists chunk', e);
        return e;
      }
    },
    parseTransferPageItems(items) {
      return items.map(transfer => {
        const parsedStartDate = moment(transfer.start_date).startOf('day');
        const parsedEndDate = moment(transfer.end_date).startOf('day');

        transfer.formatedStartDate = parsedStartDate.format('YYYY-MM-DD');
        transfer.formatedEndDate = parsedEndDate.format('YYYY-MM-DD');

        // Transfer edit button availability
        transfer.canBeEdited = true;
        const daysUntilStart = parsedStartDate.diff(this.currentDate, 'days');
        if (daysUntilStart < 1) {
          const daysUntilEnd = parsedEndDate.diff(this.currentDate, 'days');
          transfer.canBeEdited = transfer.transfer_type === TRANSFER_TYPES.TEMPORARY && daysUntilEnd >= 1;
        }

        // Transfer deletion button availability
        transfer.canDeleted = transfer.status === TRANSFER_STATUS.SCHEDULED;

        const originalPt = this.therapistsList.find(t => t.id === transfer.from_therapist_user_id) || {};
        transfer.original_pt = {
          firstname: originalPt.firstname || '-',
          lastname: originalPt.lastname || '-',
        };

        return transfer;
      });
    },
    openTransferModal() {
      this.showEditScheduledTransfer = true;
    },
    closeTransferModal() {
      this.transferToEdit = {};
      this.showEditScheduledTransfer = false;
    },
    editTransfer(transfer) {
      this.transferToEdit = transfer;
      this.openTransferModal();
    },
    confirmTransferChanges(transferData) {
      if (this.editingTransfer) {
        this.editScheduleTransfer(transferData);
      } else {
        this.scheduleNewTransfer(transferData);
      }
    },
    async editScheduleTransfer(transferData) {
      const payload = {
        transfer_type: transferData.type,
        start_date: transferData.startDate,
        end_date: transferData.endDate || undefined,
      };

      try {
        await axios.put(`v1/member-bulk-transfer/${transferData.id}`, { ...payload });
        await this.reFetchScheduledTransfers();
        this.closeTransferModal();
        this.$noty.success('Transfer successfully edited');
      } catch (e) {
        console.error(`Error editing transfer with id ${transferData.id}`, e);
      }
    },
    async scheduleNewTransfer(newTransferData) {
      const payload = {
        from_therapist_user_id: newTransferData.originPt,
        transfer_type: newTransferData.type,
        start_date: newTransferData.startDate,
        end_date: newTransferData.endDate || undefined,
      };

      try {
        await axios.post('v1/member-bulk-transfer', { ...payload });
        this.closeTransferModal();
        await this.reFetchScheduledTransfers();
        this.$noty.success('Transfer successfully scheduled');
      } catch (e) {
        console.error('Error scheduling new bulk transfer');
      }
    },
    async handleDelete(transfer) {
      try {
        const canBeDeleted = await this.$bvModal.msgBoxConfirm('Are you sure you want to delete this bulk transfer?', { okTitle: 'Delete' });
        if (canBeDeleted) await this.deleteTransfer(transfer);
      } catch (e) {
        console.error('Error deleting scheduled transfer');
      }
    },
    async deleteTransfer({ id }) {
      try {
        this.transferBeingDeleting = id;
        await axios.delete(`v1/member-bulk-transfer/${id}`);
        await this.reFetchScheduledTransfers();
        this.$noty.success('Transfer deleted successfully');
      } catch (e) {
        console.error('Error deleting scheduled transfer');
      } finally {
        this.transferBeingDeleting = null;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/_user-tables";
</style>
